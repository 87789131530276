import { Link } from "react-router-dom";
import "../styles/desktop/footer.css";
import "../styles/mobile/footer.css";

/** Global footer component */
export function Footer() {
    return (
        <footer>
            <div className="left">
                <h1>TED</h1>
                <p id="disclaimer">
                    TED is not affiliated, associated, authorized, endorsed by,
                    or in anyway officially connected with Discord Inc. or any
                    of its subsidiaries or its affiliates.
                </p>
                <p id="copyright">© 2024 TED - All rights reserved. </p>
            </div>
            <div className="right">
                <div id="ted">
                    <h3>TED</h3>
                    <ul>
                        <li>
                            <Link
                                to="https://docs.liege.dev/api/introduction"
                                target="_blank"
                            >
                                API
                            </Link>
                        </li>

                        <li>
                            <Link to="/guides">Guides</Link>
                        </li>
                        <li>
                            <Link to="https://docs.liege.dev/" target="_blank">
                                Documentation
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="https://discord.gg/uuDZzBsNvA"
                                target="_blank"
                            >
                                Support
                            </Link>
                        </li>
                        <li>
                            <Link to="/guilds">Dashboard</Link>
                        </li>
                    </ul>
                </div>
                <div id="legal">
                    <h3>Legal</h3>
                    <ul>
                        <li>
                            <Link
                                to="https://docs.liege.dev/privacy/"
                                target="_blank"
                            >
                                Privacy Policy
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="https://docs.liege.dev/terms/"
                                target="_blank"
                            >
                                Terms of Service
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="https://docs.liege.dev/cookies"
                                target="_blank"
                            >
                                Cookie Policy
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    );
}
