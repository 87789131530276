import reportImage from "../images/report.svg";
import raidImage from "../images/raid.png";
import { Section } from "../comps/Section";
import CountUp from "react-countup";
import "../styles/desktop/home.css";
import "../styles/mobile/home.css";
import { Stats, getStats } from "../models/Stats";
import { useQuery } from "@tanstack/react-query";
import { User, getUser } from "../models/User";

/** The landing page */
export function Home() {
    const query = useQuery({
        queryKey: ["stats"],
        queryFn: () => getStats(),
    });

    const stats = query.data as Stats;

    const userQuery = useQuery({
        queryKey: ["user"],
        queryFn: () => getUser(),
    });

    const user = userQuery.data as User;

    return (
        <div>
            <Section
                title="like you've never seen."
                description="Secure your Discord server effortlessly. TED actively analyzes users in your server and determines how much of a threat they are."
                img={[raidImage, "reportImage"]}
                imgRight={true}
                buttons={[
                    {
                        text: "Add to Discord",
                        link: "https://discord.com/oauth2/authorize?client_id=879360985738117120&permissions=1507532729591&scope=bot",
                        color: "#5865F2",
                    },
                    {
                        text: "Dashboard",
                        link: `${
                            user
                                ? "/guilds"
                                : "https://discord.com/oauth2/authorize?client_id=879360985738117120&response_type=code&redirect_uri=https%3A%2F%2Fapi.liege.dev%2Foauth&scope=guilds+identify"
                        }`,
                        color: "#36393F",
                    },
                ]}
                id="home1"
                firstWord="AutoModeration"
            />
            <div className="stats-parent">
                <div className="stats">
                    <div className="stat">
                        <CountUp
                            start={0}
                            end={stats !== undefined ? stats.guildCount : 0}
                            duration={2.5}
                        />
                        <h1>Servers</h1>
                    </div>
                    <div className="stat">
                        <CountUp
                            start={0}
                            end={stats ? stats.userCount : 0}
                            duration={2.5}
                        />
                        <h1>Users</h1>
                    </div>
                    <div className="stat">
                        <CountUp
                            start={0}
                            end={stats ? stats.actionCount : 0}
                            duration={2.5}
                        />
                        <h1>Actions</h1>
                    </div>
                </div>
            </div>
            <Section
                title="Clean and simple moderation"
                description="TED actively defends your Discord server from raids, scams, spam, unsolicited invites, and DOXing content. By constructing a comprehensive threat evaluation for each user, TED ensures that those deemed less trustworthy face stricter penalties for misconduct, while trusted users are treated with greater leniency."
                img={[reportImage, "warningImage"]}
                imgRight={false}
                buttons={[
                    {
                        text: "See documentation",
                        link: "https://docs.liege.dev/",
                        color: "#36393F",
                    },
                ]}
                id="home2"
                lastWord="moderation."
            />
        </div>
    );
}
